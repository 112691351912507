import { useState, useEffect } from 'react';
import useTikTokUserInfo from './hooks/useTikTokUserInfo';
import LoginTiktokButton from './componentes/LoginTiktokButton';



const TikTokManager = ({ storeName }) => {
  
    const TiktokAccessToken = localStorage.getItem(`${storeName}_tiktok_ access_token`);
    const { userInfo, loading, error } = useTikTokUserInfo(TiktokAccessToken);

    console.log("info del usuario:",userInfo);

    
    
    const handleGoToTiktok = () => {
        const clientKey = 'sbawisj5e3nu75a5pv'; 
        const redirectUri = 'https://mispacecode.com/auth/callback/'; 
        const scope = 'user.info.basic'; 
        const tiktokAuthUrl = `https://www.tiktok.com/v2/auth/authorize/?client_key=${clientKey}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;
        localStorage.setItem('tienda', storeName);
        window.location.href = tiktokAuthUrl;
        
    };

    useEffect(() => {

        console.log(userInfo);
        
        
      }, [userInfo]); 

  return (
    <div>

      <LoginTiktokButton handleGoToTiktok={handleGoToTiktok}
                         avatar_url={userInfo?.user?.avatar_url} 
                         display_name={userInfo?.user?.display_name} />

    </div>
  );
};

export default TikTokManager;



 