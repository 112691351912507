// src/components/MainBanner.js
import React from 'react';
import { Tooltip, Fab } from '@mui/material';


const LoginTiktokButton = ({handleGoToTiktok , avatar_url, display_name}) => (

    
    <Tooltip title={display_name} arrow>
        <Fab
            color="primary"
            aria-label="add"
            target='_blank'
            style={{
                position: 'fixed',
                backgroundColor: 'white',
                bottom: '20px',
                left: '30px',
            
            }}
            onClick={handleGoToTiktok} 
            >
            <img src={avatar_url} style={{ color: '#0000008a',width: '40px',height: 'auto' }} />
        </Fab>
    </Tooltip>

);

export default LoginTiktokButton;


 